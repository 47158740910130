<script>
import DiscountTextBase from '#/v-shop-base-layout/vue-src/v-shop/comp_DiscountText.vue'
export default {
	extends: DiscountTextBase,
}
</script>

<template>
	<span class="py-0 px-1 discount font-2"> {{ amount | discountPct }}% {{ text }} </span>
</template>

<style scoped></style>
